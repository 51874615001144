export const SET_INITIAL_SEARCH_MULTICITY = "setInitialSearchForMulticity"
export const SET_CITY_FRAUD_CHECK = "setCityFraudCheck"
export const SET_CITY_FRAUD_CHECK1 = "setCityFraudCheck1"
export const SET_CLEAR_FORM = "setClearFormFlag"
export const SET_REGULAR_REFUND_CANCELLATION_DETAILS = "setRegularRefundCancellationDetails"
export const INIT_PENALTIES_TAB_DATA = "initPenaltiesTabData";
export const UPDATE_PENALTIES_TAB_DATA = "updatePenaltiesTabData";
export const SET_SAVE_BUTTON_LOADER = "setSaveButtonLoader"
export const GET_FARE_ID = "getFareId";
export const SET_FARE_ID = "setFareID";

export const SET_ITINENARY_DATA = "setItinenaryData"

export const SET_TOKEN = "setToken"
export const GET_TOKEN = "getToken"
export const SET_TOKEN_REQUEST_DATA = "setTokenInitRequestData"
export const GET_TOKEN_REQUEST_DATA = "getTokenInitRequestData"
export const SET_INIT_REQUEST ="setInitReq"

export const SET_LOADING_DATA = "setLoadingData"
export const SET_SEATMAP_LOADING_DATA = "setseatMapLoading"
export const SET_CARRIER_PAID_SEAT_DATA = "setCarrierPaidSeatData"
export const SET_OPERATING_CARRIER_PAID_SEAT_DATA = "setOperatingCarrierPaidSeatData"
export const SET_MARKETING_CARRIER_PAID_SEAT_DATA = "setMarketingCarrierPaidSeatData"
export const SET_LOADING_DATA_FARE_MATRIX = "setLoadingDataFareMatrix"

export const  SET_ACTIVE_ITINENARY_RESULT_KEY =  "setActiveItinenaryResultKey"

export const SET_GROUP_ITINENARY_DATA = "setGroupItinenaryData"

export const SET_SEAT_MAP_DATA = "setSeatMapData"

export const SET_FREE_TEXT_FARE_RULES = "setfreeTextFareRules"

export const SET_STRUCTURED_FARE_RULES = "setstructuredFareRules"

export const SET_TRIP_TYPE = "setTripType";

export const SET_TIME_TAKEN = "setTimeTaken";

export const SET_FARE_OPTIONS = "setFareOptions";

export const SET_ACTIVE_FARE_ID = "setActiveFareId" 

export const SET_FARE_MATRIX =  "setFareMatrix"

export const SET_LOWEST_FARE_MATRIX = "setLowestFareMatrix"

export const SET_FARE_ID_FOR_BRANDED_FARE = "setFareIdForBrandedFare"

export const SET_ACTIVE_INDEX_FOR_BRANDED_FARE = "setActiveIndexForBrandedFare"

export const SET_FLYING_FROM = "setFlyingFrom"
export const SET_FLYING_FROM_COUNTRY = "setFlyingFromCountry"
export const SET_FLYING_TO = "setFlyingTo"
export const SET_DEPART_DATE = "setDepartDate"
export const SET_DEPART_TIME = "setDepartTime"
export const SET_RETURN_DATE = "setReturnDate"
export const SET_RETURN_TIME = "setReturnTime"
export const SET_PASSENGER_ADULT = "setPassengerAdult"
export const SET_PASSENGER_CHILDREN = "setPassengerChildren"
export const SET_PASSENGER_INFANT = "setPassengerInfant"
export const SET_CABIN = "setCabin"
export const SET_AIR_CONTRACT = "setAirContract"
export const SET_QUALIFIERS = "setQualifiers"
export const SET_AIRLINES = "setAirlines"
export const SET_MAX_CONNECTIONS = "setMaxConnections"
export const SET_CHANGES_ALLOWED = "setChangesAllowed"
export const SET_ALLIANCES = "setAlliance"
export const SET_NEARBY_AIRPORT = "setNearbyAirport"
export const SET_FLEXI_DATE = "setFlexiDate"
export const SET_ATLEAST_FREE_BAGGAGE = "setAtleastFreeBaggage"
export const SET_SPECIFIC_AIRLINES_BLANK = "setSpecificAirlinesBlank"
export const SET_PAX_CONTRACT ="setPaxContract"
export const SET_AUTOCOMPLETE_RETURN_FROM = "setAutoCompleteReturnFrom"
export const SET_AUTOCOMPLETE_RETURN_TO = "setAutoCompleteReturnTo"
export const SET_AUTOCOMPLETE_FLY_FROM_B = "setAutoCompleteFlyFrom_B"
export const SET_AUTOCOMPLETE_FLY_TO_B = "setAutoCompleteFlyTo_B"
export const SET_AUTOCOMPLETE_FLY_FROM_0 = "setAutoCompleteFlyFrom_0"
export const SET_AUTOCOMPLETE_FLY_TO_0 = "setAutoCompleteFlyTo_0"
export const SET_AUTOCOMPLETE_FLY_FROMM = "setAutoCompleteFlyFromM"
export const SET_AUTOCOMPLETE_FLY_TOM = "setAutoCompleteFlyToM"
export const SET_AUTOCOMPLETE_FLY_FROM_1 = "setAutoCompleteFlyFrom_1"
export const SET_AUTOCOMPLETE_FLY_TO_1 = "setAutoCompleteFlyTo_1"
export const SET_AUTOCOMPLETE_FLY_FROM_2 = "setAutoCompleteFlyFrom_2"
export const SET_AUTOCOMPLETE_FLY_TO_2 = "setAutoCompleteFlyTo_2"
export const SET_AUTOCOMPLETE_FLY_FROM_3 = "setAutoCompleteFlyFrom_3"
export const SET_AUTOCOMPLETE_FLY_TO_3 = "setAutoCompleteFlyTo_3"
export const SET_AUTOCOMPLETE_FLY_FROM_4 = "setAutoCompleteFlyFrom_4"
export const SET_AUTOCOMPLETE_FLY_TO_4 = "setAutoCompleteFlyTo_4"
export const SET_AUTOCOMPLETE_QUOTE_FROM = "setAutoCompleteQuoteFrom"
export const SET_AUTOCOMPLETE_QUOTE_TO = "setAutoCompleteQuoteTo"

export const SET_FLYING_FROM_TEXT_RETURN = "setFlyingFromTextReturn"
export const SET_FLYING_FROM_TEXT_OJ = "setFlyingFromTextOJ"
export const SET_FLYING_FROM_TEXT_MULTI0 = "setFlyingFromTextMulti0"
export const SET_FLYING_FROM_TEXT_MULTI1 = "setFlyingFromTextMulti1"
export const SET_FLYING_FROM_TEXT_MULTI2 = "setFlyingFromTextMulti2"
export const SET_FLYING_FROM_TEXT_MULTI3 = "setFlyingFromTextMulti3"
export const SET_FLYING_FROM_TEXT_MULTI4 = "setFlyingFromTextMulti4"

export const SET_FLYING_TO_TEXT_RETURN = "setFlyingToTextReturn"
export const SET_FLYING_TO_TEXT_OJ = "setFlyingToTextOJ"
export const SET_FLYING_TO_TEXT_MULTI0 = "setFlyingToTextMulti0"
export const SET_FLYING_TO_TEXT_MULTI1 = "setFlyingToTextMulti1"
export const SET_FLYING_TO_TEXT_MULTI2 = "setFlyingToTextMulti2"
export const SET_FLYING_TO_TEXT_MULTI3 = "setFlyingToTextMulti3"
export const SET_FLYING_TO_TEXT_MULTI4 = "setFlyingToTextMulti4"

export const SET_AUTOCOMPLETE_AIRLINES = "setAutoCompleteAirLines"
export const SET_UUID = "setUUID"
export const SET_CACHE_EXPIRED_FLAG = "setCacheExpiredFlag"
export const SET_CACHE_EXPIRED_DIALOG_FLAG = "setCacheExpireDialogFlag"
export const SET_AGENCY_LIST = "setAgencyList"
export const SET_AGENT_LIST = "setAgentList"

export const SET_CARRIER_OPTIONS  = "setCarrierOptions"
export const SET_INPUT_FIELD_COUNT = "setInputFieldCount";

export const SET_ITINENARY_ROWS_KEYS = "setItinenaryRowsKeys";
export const SET_DETAILED_ITINENARY_ROWS = "setDetailedItinenaryRows"

export const SET_OPEN_JAW_DATE_B = "setOpenJawDate_B";
export const SET_OPENJAW_TIMEB = "setOpenJawTimeB"
export const SET_OPEN_JAW_FLYING_FROM_B = "setOpenJawFlyingFrom_B";
export const SET_OPEN_JAW_FLYING_TO_B = "setOpenJawFlyingTo_B";

export const SET_MULTICITY_FLYING_FROMM = "setMultiCityFlyingFromM";
export const SET_MULTICITY_FLYING_TOM = "setMultiCityFlyingToM";

export const SET_MULTICITY_FLYING_FROM_0 = "setMultiCityFlyingFrom_0";
export const SET_MULTICITY_FLYING_TO_0 = "setMultiCityFlyingTo_0";

export const SET_MULTICITY_FLYING_FROM_1 = "setMultiCityFlyingFrom_1";
export const SET_MULTICITY_FLYING_TO_1 = "setMultiCityFlyingTo_1";

export const SET_MULTICITY_FLYING_FROM_2 = "setMultiCityFlyingFrom_2";
export const SET_MULTICITY_FLYING_TO_2 = "setMultiCityFlyingTo_2";

export const SET_MULTICITY_FLYING_FROM_3 = "setMultiCityFlyingFrom_3";
export const SET_MULTICITY_FLYING_TO_3 = "setMultiCityFlyingTo_3";

export const SET_MULTICITY_FLYING_FROM_4 = "setMultiCityFlyingFrom_B";
export const SET_MULTICITY_FLYING_TO_4 = "setMultiCityFlyingTo_B";

export const SET_MULTYCITY_FLYING_FROM_COUNTRYM = "setMultiCityFlyingFromCountryM"
export const SET_OPEN_JAW_FLYING_FROM_COUNTRY_B= "setOpenJawFlyingFromCountry_B" 
export const SET_MULTYCITY_FLYING_FROM_COUNTRY_4= "setMultiCityFlyingFromCountry_4"
export const SET_MULTYCITY_FLYING_FROM_COUNTRY_3= "setMultiCityFlyingFromCountry_3"
export const SET_MULTYCITY_FLYING_FROM_COUNTRY_2= "setMultiCityFlyingFromCountry_2"
export const SET_MULTYCITY_FLYING_FROM_COUNTRY_1= "setMultiCityFlyingFromCountry_1"
export const SET_MULTYCITY_FLYING_FROM_COUNTRY_0= "setMultiCityFlyingFromCountry_0"
export const SET_PNR_REMARK_CITY_FRAUD_CHECK = "setPNRRemarkCityFraudCheck"

export const SET_MULTICITY_DATE_0 = "setMultiCityDate_0";
export const SET_MULTICITY_DATE_1 = "setMultiCityDate_1";
export const SET_MULTICITY_DATE_2 = "setMultiCityDate_2";
export const SET_MULTICITY_DATE_3 = "setMultiCityDate_3";
export const SET_MULTICITY_DATE_4 = "setMultiCityDate_4";
export const SET_MULTICITY_DATEM = "setMultiCityDateM";

export const SET_MULTICITY_TIME0 = "setMultiCityTime0";
export const SET_MULTICITY_TIME1 = "setMultiCityTime1";
export const SET_MULTICITY_TIME2 = "setMultiCityTime2";
export const SET_MULTICITY_TIME3 = "setMultiCityTime3";
export const SET_MULTICITY_TIME4 = "setMultiCityTime4";
export const SET_MULTICITY_TIMEM = "setMultiCityTimeM"

export const MULTICITY_ADDINPUT = "MULTICITY_ADDINPUT";
export const MULTICITY_REMOVEINPUT = "MULTICITY_REMOVEINPUT";

export const SET_REQUEST_OPTIONS = "setRequestOptions"

export const SET_ITINENARY_INDEX = "setItinenaryIndex"
export const SET_BOOKING_ITINENARY = "setBookingItinenary"
export const SET_BOOKING_HEADER = "setBookingHeader"
export const SET_BOOKING_FAREID = "setBookingFareId"
export const SET_PAYMENT_DETAILS_ADDONS = "setPaymentDetailsAddons"
export const SET_PAX_FARE_DETAILS = "setPaxFareDetails"

export const SET_FLYING_FROM_CITY = "setFlyingFromCity"
export const SET_FLYING_TO_CITY = "setFlyingToCity"
export const SET_OPEN_JAW_FLYING_FROM_CITY_B = "setOpenJawFlyingFromCity_B"
export const SET_MULTI_CITY_FLYING_FROM_CITYM = "setMultiCityFlyingFromCityM"
export const SET_MULTI_CITY_FLYING_TO_CITYM = "setMultiCityFlyingToCityM"
export const SET_OPEN_JAW_FLYING_TO_CITY_B = "setOpenJawFlyingToCity_B"
export const SET_MULTI_CITY_FLYING_FROM_CITY_0 = "setMultiCityFlyingFromCity_0"
export const SET_MULTI_CITY_FLYING_TO_CITY_0 = "setMultiCityFlyingToCity_0"
export const SET_MULTI_CITY_FLYING_FROM_CITY_1 = "setMultiCityFlyingFromCity_1"
export const SET_MULTI_CITY_FLYING_TO_CITY_1 = "setMultiCityFlyingToCity_1"
export const SET_MULTI_CITY_FLYING_FROM_CITY_2 = "setMultiCityFlyingFromCity_2"
export const SET_MULTI_CITY_FLYING_TO_CITY_2 = "setMultiCityFlyingToCity_2"
export const SET_MULTI_CITY_FLYING_FROM_CITY_3 = "setMultiCityFlyingFromCity_3"
export const SET_MULTI_CITY_FLYING_TO_CITY_3 = "setMultiCityFlyingToCity_3"
export const SET_MULTI_CITY_FLYING_FROM_CITY_4 = "setMultiCityFlyingFromCity_4"
export const SET_MULTI_CITY_FLYING_TO_CITY_4 = "setMultiCityFlyingToCity_4"

export const SET_SHOW_AGENCY_INFO = "setShowAgencyInfo"


export const SET_KEY_FOR_BRANDED_FARE_ID = "setKeyForBrandedFareId"
export const SET_RADIO_CLICKED_STATUS = "setRadioClickedStatus"

export const SET_SEGMENT ="setSegment"
export const SET_ITINARY_FARE="setItinaryFare"
export const SET_PNR="setPnr"
export const SET_PASSENGER_DATA="setPassengerData"
export const SET_BOOKING_DETAIL = "setBookingDetail"
export const SET_TOTAL_SELLING_PRICE = "setTotalSellingPrice"

export const SET_CREATE_BOOKING_DATA = "setCreateBookingData"
export const SET_PRICING_FARE_DATA = "setPricingFareData"
export const SET_SSR_LOADER_FLAG = "setSsrLoaderFlag"
export const SET_SSR_SAVE_EDIT_FLAG = "setSsrSaveEditFlag"
// Validation 
export const SET_FLYING_FROM_VALIDATION = "setFlyingFromValidation"
export const SET_FLYING_TO_VALIDATION = "setFlyingToValidation"
export const SET_DEPARTURE_DATE_VALIDATION = "setDepartureDateValidation"
export const SET_RETURN_DATE_VALIDATION = "setReturnDateValidation"
export const SET_IS_ERROR_INCREMENT= "setIsErrorIncrement"
export const SET_IS_ERROR_DECREMENT= "setIsErrorDecrement"

export const SET_IS_ERROR_ROUNDTRIP= "setIsErrorRoundTrip"
export const SET_IS_ERROR_ONE_WAY= "setIsErrorOneWay"
export const SET_IS_ERROR_OPEN_JAW= "setIsErrorOpenJaw"
export const SET_IS_ERROR_MULTI_CITY= "setIsErrorMultiCity"
export const SET_IS_ERROR_INFANT = "setIsInfantError";
export const SET_IS_PASSPORT_MANDATORY = "setIsPassportMandatory";
export const SET_IS_EMAIL_MANDATORY = "setIsEmailMandatory";
export const SET_IS_MOBILE_MANDATORY = "setIsMobileMandatory";

//Saved Quotes
export const SET_TODAYS_QUOTES = "setTodaysQuotes"
export const SET_YESTERDAYS_QUOTES = "setYesterdaysQuotes"
export const SET_LAST_WEEK_QUOTES = "setLastWeeksQuotes"
export const SET_ARCHEIVED_QUOTES = "setArcheivedQuotes"
export const SET_QUOTE_DETAILS = "setQuoteDetails"
export const SET_REPRICE_QUOTE = "setRepriceQuote"
export const SET_QUOTE_TOKEN = "setQuoteToken"
export const SET_SHOW_EMAIL_QUOTE = "setShowEmailQuote"
export const SET_BOOKING_WITH_QUOTE = "setBookingWithQuote"
// MarkUp value
export const SET_ADULT_MARKUP_VALUE = "setAdultMarkupValue"
export const SET_CHILD_MARKUP_VALUE = "setChildMarkupValue"
export const SET_INFANT_MARKUP_VALUE = "setInfantMarkupValue"
export const SET_EQUIVALENT_FARE_VALUE = "setEquivalentFareValue"
export const SET_EQUIVALENT_FARE_LOADER = "setEquivalentFareLoader"


//MyFiles
export const SET_MYFILES="setMyFiles"

export const SET_BOOKING_PAYMENT_STATUS = "setBookingPaymentStatus"
export const SET_ITINARY_FARES_TYPE = "setItinaryFaresType"
export const SET_BOOKING_DATE = "setBookingDate"
export const SET_MODIFY_SEARCH_VALUE = "setModifySearchValue"
export const SET_REPRICE_FARE = "setRepriceFare"
export const SET_REPRICE_FARE_LOADER = "setRepriceFareLoader"

//Agent 
export const SET_AGENT_AUTHETICATION = "setAgentAuthetication"
export const SET_AGENT_PROFILE = "setAgentProfile"
export const SET_ORG_ID = "setOrgId"
export const SET_AGENCY_ID = "setAgencyId"
export const SET_AGENCY_GROUP_ID = "setAgencyGroupId"
export const SET_USER_ID = "setUserId"
export const SET_INTERNAL_USER_P6 = "setInternalUserP6"
export const SET_AGENCY_LOCATION = "setAgencyLocation"
export const SET_AGENT_ID = "setAgentId"
export const SET_AGENT_NAME = "setAgentName"
export const SET_AGENT_TOKEN ="setAgentToken"
export const SET_AGENCY_DETAILS = "setAgencyDetails"
export const SET_AGENT_DETAILS = "setAgentDetails"
export const SET_AGENCY_FIELD = "setAgencyField"
export const SET_AGENT_FIELD = "setAgentField"
export const SET_FLIGHT_TYPE = "setFlightType"
export const SET_URL_USD = "setUrlUsd"
export const SET_AGENT_OLD_USER_ID = "setAgentOldUserId"

export const SET_AGENT_CODE = "setAgentCode"
export const SET_AGENCY_NAME = "setAgencyName"
export const SET_AGENT_EMAIL_ID="setAgentEmailId"


export const SET_BRANDED_ITINENARY_ID = "setBrandedItinenaryId"
export const SET_SEGMENT_ARRAY_LENGTH = "setSegmentArrayLength"
export const SET_CLIENT_NAME = "setClientName"
export const SET_BOOKING_STATUS="setBookingStatus"
export const SET_PASSENGER_FRAUD_LIST = "setPassengerFraudList"
export const SET_FRAUD_USER_EXISTS = "setFraudUserExists"
export const SET_FRAUD_USER_EXISTS_WITH_PAYMENT = "setFraudUserExistsWithPayment"
export const SET_FRAUD_USER_FLAG = "setFraudUserFlag"
export const SET_BOOKING_STATUS_CODE = "setBookingStatusCode"

export const SET_COUNT = "setCount"

export const SET_BRAND_CODE = "setBrandCode"
export const SET_INBOUND_BRAND_DATA = "setInboundBrandData"

export const PRICE_STATUS="priceStatus"

export const SET_PNR_CANCELLATION_STATUS = "setPnrCancellationStatus"
export const SET_CANCELLATION_POPUP = "setCancellationPopUp"
export const SET_CANCELLATION_STATUS = "setCancellationStatus"
export const SET_CANCELLATION_CONFIRMATION = "setCancellationConfirmation"

export const SET_QUOTES_TAB_TYPE = "setQuotesTabType"

export const SET_PROVIDER_ID ="setProviderId"

export const SET_TIME_CALCULATOR = "setTimeCalculator"

export const SET_PROMOTION_DATA = "setPromotionData"


export const SET_GROUP_ITINENARY_TIME_CALCULATOR = "setGroupItinenaryTimeCalculator"

export const SET_API_RENDER_FLAG = "setApiRenderFlag"
export const SET_BRANDED_ITINARY_FARE ="setBrandedItinaryFare"
export const SET_SEATMAP_HEADER ="setSeatMapHeader"

export const SET_GRP_ITINENARY_FARE_ID = "setGrpItinenaryFareId"

export const SET_PASSENGER_DETAILS_ARRAY = "setPassengerDetailsArray"
export const SET_BOOK_WITH_PAYMENT_FLAG = "setBookWithPaymentFlag"
export const SET_APPLY_BUTTON_CLICK_FLAG = "setApplyButtonClickFlag"
export const SET_LAND_CRUISE_BOOKING_NUMBER = "setLandCruiseBookingNumber"
export const SET_AGENT_EMERGENCY_NUMBER = "setAgentEmergencyNumber"
export const SET_VALIDATION_ON_LAND_CRUISE = "setValidationOnLandCruise"
export const SET_EMAIL_LANGUAGE = "setEmailLanguage"
export const SET_LAND_CRUISE_BOOKING_NUMBER_CHECK = "setLandCruiseBookingNumberCheck"

export const SET_SEAT_MAP_DETAIL = "setSeatMapDetail"
export const SET_SEAT = "setSeatData"
export const SET_FLIGHT_DATA = "setFlightData"
export const SET_PREV_SEAT ="setPrevSeat"
export const SET_PNR_PRICE ="setPnrPrice"
export const SET_EDIT_SEAT="setEditSeat"

export const SET_SAVE_SEAT ="setSaveSeat"
export const SET_PREPAID_SEAT_STATUS = "setPrePaidSeatStatus"
export const SET_EDIT_SEAT_RECORD = "setEditSeatRecord"

export const SET_PNR_TOKEN = "setPnrToken"
export const SET_BRANDED_STATUS ="setBrandedStatus"
export const SET_SEARCH_STATUS ="setSearchStatus"

export const SET_PAYMENT_RULES ="setPaymentRules"
export const SET_PAYMENT_RULES_FOR_CHEQUE = "setPaymentRulesForCheque"
export const SET_MARKUP_STATUS ="setMarkupStatus"
export const SET_TRIP_TYPE_FROM_API = "setTripTypeFromApi"
export const SET_MARKUP_LOADER = "setMarkupLoader"
export const SET_MARKUP_BUTTON_CHECK = "setMarkupButtonCheck"
export const SET_MARKUP_BUTTON_COUNT = "setMarkupButtonCount"

export const SET_DEPART_FROM_CITY = "setDepartFromCity"
export const SET_ARRIVE_FROM_CITY = "setArriveFromCity"
export const SET_DEPART_FROM_CODE = "setDepartFromCode"
export const SET_ARRIVE_FROM_CODE = "setArriveFromCode"

export const SET_FLYING_FROM_MODIFY_SEARCH = "setFlyingFromModifySearch";
export const SET_FLYING_FROM_MODIFY_SEARCH_CODE = "setFlyingFromModifySearchCode"
export const SET_FLYING_TO_MODIFY_SEARCH = "setFlyingToModifySearch";
export const SET_FLYING_TO_MODIFY_SEARCH_CODE = "setFlyingToModifySearchCode"
export const SET_DEPART_DATE_MODIFY_SEARCH = "setDepartDateModifySearch";
export const SET_RETURN_DATE_MODIFY_SEARCH = "setReturnDateModifySearch";

//internal user login flow
export const SET_INTERNAL_USER_LOGIN = "setInternalUserLogin"
export const SET_INTERNAL_USER_ACCESS_TOKEN = "setInternalUserAccessToken"
export const SET_LOGGED_IN_USER_DATA = "setLoggedInUserData"
export const AGENT_PROFILE_FEE="agentProfileFee"
export const SET_AGENCY_AGENT_FIELD_FLAG = "setAgencyAgentFieldFlag"

export const SET_ALLIANCE_OPTION = "setAllianceOption";
export const SET_INTERNAL_AGENT_ID ="setInternalAgentId"

export const SET_ADULT_COUNT = "setAdultCount";
export const SET_CHILDREN_COUNT ="setChildrenCount";
export const SET_INFANT_COUNT="setInfantCount";
export const SET_MYFILE_FLAG="setMyfilesCount";
export const SET_DFAULT_BRAND_DETAILS="setDefaultBrandDetails"

export const SET_AUTOCOMPLETE_COUNTRY = "setAutoCompleteCountry"
export const SET_MYFILESERROR = "setMyFileError"
export const SET_GET_PNR_RESULT = "setGetPnrResult"

//Frequent Flyer
export const SET_FREQUENT_FLYER_DROPDOWN = "setFrequentFlyerDropdown"


export const SET_FARE_RULES_ERROR = "setFareRulesError";

//Fare and Seat Payment
export const SET_FARE_PAYMENT_ATTEMPT = "setFarePaymentAttempt";
export const SET_SEAT_PAYMENT_ATTEMPT = "setSeatPaymentAttempt";
export const SET_FARE_PAYMENT_STATUS = "setFarePaymentStatus";
export const SET_SEAT_PAYMENT_STATUS = "setSeatPaymentStatus";


// Payment FOP selection

export const SET_FOP_SELECTION = "setFopSelection"
export const SET_APPLY_PAYMENT_METHOD = "setApplyPaymentMethod"


// Calculator

export const SET_FLOW_FROM = "setFlowFrom";
export const SET_CALC_ERRORS = "setCalcErrors"
export const SET_EXISTING_FARE_REMARK_CHECK = "setExistingFareRemarkCheck"


// FlightType
export const SET_FLIGHT_TYPE_CHECK = "setFlightTypeCheck"

export const SET_FARE_RULES_LOADER = "setFareRulesLoader"

export const SET_FROM_FLEXI_DATE_SEARCH = "setFromFlexiDateSearch"

// Trip Type Flow
export const SET_TRIPTYPE_FLOW_FROM = "setTripTypeFlowFrom"

export const SET_REPRICE_PNR_RADIO_BUTTON = "setRepricePnrRadioButton"
export const SET_BRANDED_FARE_VALUE = "setIsBrandedFareValue"

//Save Selected Branded Fare
export const SET_SELECTED_BRANDED_FARE = "setSelectedBrandedFare"

// Calculator
export const SET_DISABLE_SAVE_BUTTON = "setDisableSaveButton"
export const SET_UPDATE_PNR_CALCULATOR_FLOW = "setUpdatePnrCalculatorFlow"

// Seat Payment
export const ALLOW_SEAT_PAYMENT = "setAllowSeatPayment"

//Calculator
export const OPEN_QUOTE_DIALOG = "setOpenQuoteDialog"
export const SET_AUTO_REPRICE= "setAutoReprice"

// Booking Page
export const SET_PAX_TYPE_CODE_ERROR_FLAG = "setPaxTypeCodeErrorFlag"

//Two One Way
export const SET_ROUND_TRIP_TYPE = "setRoundTripType"
export const SET_TOW_GROUPED_ITINENARY = "setTowGroupedItinenary"
export const SET_SELECTED_TOW_DATA = "setSelectedTowData"
export const SET_TOW_TOKEN = "setTowToken"

// Duffle
export const SET_PROVIDER_TYPE = "setProviderType"
export const SET_OFFERID_TIMEOUT = "setOfferIdTimeout"
export const SET_EXPIRE_TIME = "setExpireTime"
export const SET_SEND_AUTO_EMAIL= "setSendAutoEmail"
export const SET_PASSENGER_INFO_FOR_LATER_PAY = "setPassengerInfoForLaterPay";
export const SET_DEPARTURE_DATE_MODIFY_SEARCH_FLEXI = "setDepartureDateModifySearchFlexi";
export const SET_RETURN_DATE_MODIFY_SEARCH_FLEXI = "setReturnDateModifySearchFlexi"
export const SET_AUTO_EMAIL_TYPE = "setAutoEmailType"

export const SET_APPLY_MARKUP_BUTTON = "setApplyMarkupButton"
export const SET_CANCELLATION_BOOKING_CODE = "setCancellationBookingCode"
export const SET_APPLY_MARKUP_POPUP = "setMarkupPopUp"
export const SET_INITIAL_ITINERARY_FARE = "setInitialItineraryFare"
export const SET_INITIAL_PAYMENT_RULES = "setInitialPaymentRules"
export const SET_INITIAL_PAYMENT_RULES_CHQ = "setInitialPaymentRulesChq"
export const SET_ROUND_TRIP_ITINENARY_DATA = "setRoundTripItinenaryData"
export const SET_UNSAVED_CHANGES_FLAG = "setUnSavedChangesInMarkupFlag"
export const SET_ENABLE_BRANDED_FARES = "setEnableBrandedFares"
export const SET_ENABLE_PER_PASSENGER = "setEnablePerPassenger"
export const SET_OUTBOUND_BRAND_CODES="setOutBoundBrandCodes"
export const SET_INBOUND_BRAND_CODES="setInBoundBrandCodes"
export const SET_OUTBOUND_BRAND_CODE_PER_PASSENGERS="setOutBoundBrandCodePerPassengers"
export const SET_INBOUND_BRAND_CODE_PER_PASSENGERS ="setInBoundBrandCodePerPassengers"
export const SET_VALIDATING_CARRIER = "setValidatingCarrier"
export const SET_BRANDCODE_OUT_DROPDOWN = "setBrandCodeOutDropdown"
export const SET_SAVE_CALCULATOR_FLOW = "setSaveCalculatorFlow"
export const SET_REPRICE_FLAG = "setRepriceFlag"
export const SET_BRANDCODE_IN_DROPDOWN = "setBrandCodeInDropdown"
export const SET_REPRICE_LOADER = "setRepriceLoader"
export const SET_OUTBOUND_OPTION = "setOutboundOption"
export const SET_INBOUND_OPTION = "setInboundOption"
export const SET_PREVIOUS_VC = "setPreviousVC"
export const SET_ISNONGIGI_PNR = "setNonGigiPNR"
export const SET_IS_UNACCOMPANIED_MINOR = "setIsUnaccompaniedMinor"
export const SET_RESET_CALC_DATA = "setResetCalcData"
export const SET_IS_ITINERARY_CHANGED = "setIsItineraryChanged"
export const SET_IS_RETRY_REQUEST_ERROR = "setIsRetryRequestError"
export const SET_UPDATE_BOOKING_STATUS_POP_UP = "setUpdateBookingStatusPopUp"
export const SET_PROVIDER_NAME = "setProviderName"