// import bags from "../../Assets/bag-icon.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../../LangConfig";
import { FlightLogo, TripType } from "../../utils/constant";
import { getFlightLogo, titleCase } from "../../utils/utility/commenFunctions";
function BaggageTab(props) {
  const { t } = useTranslation();
  const lang = useSelector( (state) => state.lang.lang)
  var paxType = [];
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);

  props.baggagefares.map((item) => {
    item &&
      item.map((element) => {
        paxType.push(element.paxType);
      });
  });

  var flightNamesArray = [];

  let flightDetails = []
  if(props?.flowFrom=="towSearchResult"){
    flightDetails=props.flightDetails.flights

  }else{
    flightDetails=props.flightDetails &&
    Object.values(props.flightDetails)[0] &&
    Object.values(props.flightDetails)[0].flights;
  }

  if (tripTypeValue !== TripType.MULTICITY) {
    flightDetails &&
      flightDetails.map((flight) => {
        flightNamesArray.push({
          flightName: flight.departureAirport + "-" + flight.arrivalAirport,
          carrier: flight.carrier,
          flightNumber: flight.number,
        });
      });
  }

  if (tripTypeValue === TripType.MULTICITY) {
    props.flightDetails &&
      props.flightDetails.map((flight) => {
        let flights = flight && Object.values(flight)[0].flights;
        flights &&
          flights.map((flight) => {
            flightNamesArray.push({
              flightName: flight.departureAirport + "-" + flight.arrivalAirport,
              carrier: flight.carrier,
              flightNumber: flight.number,
            });
          });
        });
      }

      // logic for language selection
      useEffect(() =>{i18n.changeLanguage(lang)},[lang])

      
  return (
    <>
      <div className="fd-baggage">
        {flightNamesArray &&
          flightNamesArray.map((flight, flightIndex) => {
            let flightData;

            if (tripTypeValue !== TripType.MULTICITY) {
              flightData = flightDetails.find(
                (obj) => obj.number === flight.flightNumber
              );
            } else {
              flightData = props.flightDetails.find(
                (obj) => obj.number === flight.flightNumber
              );
            }

            const baggagefaresObject =
              props.baggagefares && props.baggagefares[0]
                ? props.baggagefares[flightIndex]
                : flightData && flightData.fareBasisDetails;
           
            let carrierCode = flight && flight.carrier && flight.carrier
            let flightLogo = getFlightLogo(carrierCode)
            return (
              <>
                <div className="fd-baggage-flight">
                  <img src={flightLogo} alt="flight img" />
                  {flight && flight.flightName} (
                  {flight && flight.carrier + " " + flight.flightNumber})
                </div>

                <div className="fdb-table-head">
                  <div className="fdb-th-col fdb-type-head">{t("Type")}</div>
                  <div className="fdb-th-col fdb-checkin-head">{t("Carry-on")}</div>
                  <div className="fdb-th-col fdb-checkin-head">{t("Check-in")}</div>
                  <div className="fdb-th-col fdb-addiBag-head">
                    {t("Additional Bags")}<span>({t("up to")} 62 in x 158 cms )</span>
                  </div>
                </div>

                {baggagefaresObject &&
                  baggagefaresObject.map((item) => {
                    const count =
                      item &&
                      item.chargedBaggageDetails &&
                      item.freeBaggageDetails
                        ? item.chargedBaggageDetails.length >
                          item.freeBaggageDetails.length
                          ? item.chargedBaggageDetails.length
                          : item.freeBaggageDetails.length
                        : baggagefaresObject.length;
                    let cntArray = [];
                    let i = 0;
                    while (i !== count) {
                      cntArray.push(i);
                      i++;
                    }

                    let baggageData;
                    if (
                      item.freeBaggageDetails &&
                      item.freeBaggageDetails[0] &&
                      item.freeBaggageDetails[0].quantity
                    ) {
                      baggageData =
                        item.freeBaggageDetails[0].quantity + t("Piece");
                    } else if (item.freeBaggage && item.freeBaggage.quantity) {
                      if (Number(item.freeBaggage.quantity) === 0) {
                        baggageData = item.freeBaggage.weight + " KG";
                      } 
                      if (
                        Number(item.freeBaggage.quantity) === 0 &&
                        Number(item.freeBaggage.weight) === 0
                      ) {
                        baggageData = item.freeBaggage.quantity + t("Piece");
                      } else if(Number(item.freeBaggage.quantity) !== 0){
                        baggageData = item.freeBaggage.quantity + t("Piece");
                      }
                    }

                    if (
                      item.freeBaggageDetails &&
                      item.freeBaggageDetails[0] &&
                      item.freeBaggageDetails[0].weight &&
                      item.freeBaggageDetails[0].weight !== 0
                    ) {
                      baggageData = item.freeBaggageDetails[0].weight + " KG";
                    }
                    //time being implementation done, till api returns the correct baggage for each flight
                    if (props?.baggage?.baggage != undefined && props?.baggage?.weight != undefined) {
                      if (Number(props?.baggage?.baggage) == 0 && Number(props?.baggage?.weight) == 0) {
                        baggageData = String(props?.baggage?.baggage)
                      } else {
                        baggageData = Number(props?.baggage?.baggage) > Number(props?.baggage?.weight) ? String(props?.baggage?.baggage) + t("Piece") : String(props?.baggage?.weight) + " KG";
                      }
                    }
                    
                    return (
                      <>
                        {
                          <div className="fdb-table-item">
                            <div className="fdb-tb-type">{t(item.paxType)}</div>
                            <div className="fdb-tb-type"> {t("Restriction may apply. Please contact the carrier")}</div>
                            <div className="fdb-tb-type">{baggageData}</div>

                            <div className="fdb-tb-chekBag">
                              {cntArray &&
                                cntArray.length > 0 &&
                                cntArray.map((index, k) => {
                                  let bagCount = [];
                                  let j = 0;
                                  while (j !== index + 1) {
                                    bagCount.push(j);
                                    j++;
                                  }

                                  if (item.chargedBaggageDetails) {
                                  }
                                  let amount =
                                    item.chargedBaggageDetails &&
                                    item.chargedBaggageDetails[index] &&
                                    item.chargedBaggageDetails[index].amount;
                                  let desc =
                                    item.chargedBaggageDetails &&
                                    item.chargedBaggageDetails[index] &&
                                    item.chargedBaggageDetails[index].chargeDescription ?
                                    item.chargedBaggageDetails[index].chargeDescription.length == 1 ? item.chargedBaggageDetails[index].chargeDescription[0] : item.chargedBaggageDetails[index].chargeDescription[index] : item?.chargedBaggageDetails?.[index]?.descriptions[index]
                                  let quantity =
                                    item.chargedBaggageDetails &&
                                    item.chargedBaggageDetails[index] &&
                                    item.chargedBaggageDetails[index]
                                      .firstPiece;

                                     
                                      let additionalBagDesc = desc;
                                      const mapObj = {
                                        POUNDS: "lbs",
                                        KILOGRAMS: "Kgs",
                                        LINEAR:"",
                                        INCHES:"In",
                                        CENTIMETERS:"cms"
                                        
                                      };
                                      additionalBagDesc = additionalBagDesc && additionalBagDesc.replace(/\b(?:POUNDS|KILOGRAMS|LINEAR|INCHES|CENTIMETERS)\b/gi, matched => mapObj[matched]);
                                      
                                      if(lang=="fr"){
                                      additionalBagDesc = additionalBagDesc && additionalBagDesc.replace("UP TO","Jusqu'à")
                                      additionalBagDesc = additionalBagDesc && additionalBagDesc.replace("In","Po")
                                    }

                                  return (
                                    <>
                                      <div className={cntArray.length == (k + 1) ? "fdb-tr" : "fdb-tr showBorder"}>                                     
                                        <span className="fdb-count">{quantity && quantity + t("Piece")}</span>
                                        <span className="fdb-details">
                                        {amount ? (
                                          <><strong>{ `${window.selectedDollarValue} `} {amount}</strong> </>
                                        ) : (
                                          <> - </> 
                                        )}
                                        <>{additionalBagDesc && titleCase(additionalBagDesc)}</>  
                                        </span>                                    
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
              </>
            );
          })}
      </div>
    </>
  );
}

export default BaggageTab;
